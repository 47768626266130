<template>
  <div class="joinOur">
    <div class="head-box">
      <div class="head">
        <img :src="windowWidth>992?banner.pcBanner:banner.mbBanner" alt="">
      </div>
    </div>
    <div class="content-box">
      <div class="content">
        <div class="content-left">
          <div class="content-left-title">
            在招职位
          </div>
          <div class="jobInfo-box">
            <div class="jobInfo">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="研发" name="first">
                <div class="jobInfo-item-one" v-for="(item, index) in codeList" :key="index + 'a'">
                  <div class="jobInfo-item">
                    <div class="jobInfo-item-left">
                      <div>{{ item.title }}</div>
                      <!-- <div>工作地点：{{ item.location }}&nbsp;&nbsp;薪资：{{ item.salary }}
                      &nbsp;&nbsp;招聘人数：3</div> -->
                    </div>
                    <div class="jobInfo-item-right" @click="codeViewDetailBtn(item)">查看详情<i :class="{ 'el-icon-arrow-down': item.codeShow ? false : true, 'el-icon-arrow-up': item.codeShow ? true : false }"></i>
                    </div>
                  </div>
                  <div class="jobInfo-item-content" v-if="item.codeShow">
                    <div class="jobInfo-item-content-title">工作职责：</div>
                    <div v-for="(tex1, index) in item.text1" :key="index + 'b'">{{ tex1 }}</div>
                    <!-- <div>2、能独立完成游戏逻辑的设计和开发；</div>
                    <div>3、接入常见的第三方平台；</div>
                    <div>4、与团队成员能良好地沟通，协同解决各类开发中的问题。</div> -->
                    <div class="jobInfo-item-content-title">任职资格：</div>
                    <div v-for="(tex2, index) in item.text2" :key="index + 'c'">{{ tex2 }}</div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="运营" name="second">
                <div class="jobInfo-item-one" v-for="(item, index) in operationList" :key="index">
                  <div class="jobInfo-item">
                    <div class="jobInfo-item-left">
                      <div>{{ item.title }}</div>

                    </div>
                    <div class="jobInfo-item-right" @click=" operationViewDetailBtn(item)">查看详情<i :class="{ 'el-icon-arrow-down': item.codeShow ? false : true, 'el-icon-arrow-up': item.codeShow ? true : false }"></i>
                    </div>
                  </div>
                  <div class="jobInfo-item-content" v-if="item.codeShow">
                    <div class="jobInfo-item-content-title">工作职责：</div>
                    <div v-for="(tex1, index) in item.text1" :key="index">{{ tex1 }}</div>
                    <div class="jobInfo-item-content-title">任职资格：</div>
                    <div v-for="(tex2) in item.text2" :key="tex2">{{ tex2 }}</div>
                  </div>
                </div>

              </el-tab-pane>
              <!-- <el-tab-pane label="策划" name="third">
                <div class="jobInfo-item-one" v-for="(item, index) in planList" :key="index">
                  <div class="jobInfo-item">
                    <div class="jobInfo-item-left">
                      <div>{{ item.title }}</div>

                    </div>
                    <div class="jobInfo-item-right" @click=" planViewDetailBtn(item)">查看详情<i :class="{ 'el-icon-arrow-down': item.codeShow ? false : true, 'el-icon-arrow-up': item.codeShow ? true : false }"></i>
                    </div>
                  </div>
                  <div class="jobInfo-item-content" v-if="item.codeShow">
                    <div class="jobInfo-item-content-title">工作职责：</div>
                    <div v-for="(tex1, index) in item.text1" :key="index">{{ tex1 }}</div>

                    <div class="jobInfo-item-content-title">任职资格：</div>
                    <div v-for="(tex2) in item.text2" :key="tex2">{{ tex2 }}</div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="美术" name="fourth1">
                <div class="jobInfo-item-one" v-for="(item, index) in artList" :key="index">
                  <div class="jobInfo-item">
                    <div class="jobInfo-item-left">
                      <div>{{ item.title }}</div>

                    </div>
                    <div class="jobInfo-item-right" @click=" artViewDetailBtn(item)">查看详情<i :class="{ 'el-icon-arrow-down': item.codeShow ? false : true, 'el-icon-arrow-up': item.codeShow ? true : false }"></i>
                    </div>
                  </div>
                  <div class="jobInfo-item-content" v-if="item.codeShow">
                    <div class="jobInfo-item-content-title">工作职责：</div>
                    <div v-for="(tex1, index) in item.text1" :key="index">{{ tex1 }}</div>
                    <div class="jobInfo-item-content-title">任职资格：</div>
                    <div v-for="(tex2) in item.text2" :key="tex2">{{ tex2 }}</div>
                  </div>
                </div>
              </el-tab-pane> -->
              <!-- <el-tab-pane label="市场" name="fourth2">
              <div class="jobInfo-item-one" v-for="(item, index) in saleList" :key="index">
                <div class="jobInfo-item">
                  <div class="jobInfo-item-left">
                    <div>{{ item.title }}</div>

                  </div>
                  <div class="jobInfo-item-right" @click=" saleViewDetailBtn(item)">查看详情<i
                      :class="{ 'el-icon-arrow-down': item.codeShow ? false : true, 'el-icon-arrow-up': item.codeShow ? true : false }"></i>
                  </div>
                </div>
                <div class="jobInfo-item-content" v-if="item.codeShow">
                  <div class="jobInfo-item-content-title">工作职责：</div>
                  <div v-for="(tex1, index) in item.text1" :key="index">{{ tex1 }}</div>

                  <div class="jobInfo-item-content-title">任职资格：</div>
                  <div v-for="(tex2) in item.text2" :key="tex2">{{ tex2 }}</div>
                </div>
              </div>
            </el-tab-pane> -->
              <!-- <el-tab-pane label="商务" name="fourth3">
                <div class="jobInfo-item-one" v-for="(item, index) in businessList" :key="index">
                  <div class="jobInfo-item">
                    <div class="jobInfo-item-left">
                      <div>{{ item.title }}</div>

                    </div>
                    <div class="jobInfo-item-right" @click="businessViewDetailBtn(item)">查看详情<i :class="{ 'el-icon-arrow-down': item.codeShow ? false : true, 'el-icon-arrow-up': item.codeShow ? true : false }"></i>
                    </div>
                  </div>
                  <div class="jobInfo-item-content" v-if="item.codeShow">
                    <div class="jobInfo-item-content-title">工作职责：</div>
                    <div v-for="(tex1, index) in item.text1" :key="index">{{ tex1 }}</div>
                    <div class="jobInfo-item-content-title">任职资格：</div>
                    <div v-for="(tex2) in item.text2" :key="tex2">{{ tex2 }}</div>
                  </div>
                </div>
              </el-tab-pane> -->
              <el-tab-pane label="其他" name="fourth4">
                <div class="jobInfo-item-one" v-for="(item, index) in otherList" :key="index">
                  <div class="jobInfo-item">
                    <div class="jobInfo-item-left">
                      <div>{{ item.title }}</div>
                      <!-- <div>工作地点：{{ item.location }}&nbsp;&nbsp;薪资：{{ item.salary }}
                      &nbsp;&nbsp;招聘人数：3</div> -->
                    </div>
                    <div class="jobInfo-item-right" @click="otherViewDetailBtn(item)">查看详情<i :class="{ 'el-icon-arrow-down': item.codeShow ? false : true, 'el-icon-arrow-up': item.codeShow ? true : false }"></i>
                    </div>
                  </div>
                  <div class="jobInfo-item-content" v-if="item.codeShow">
                    <div class="jobInfo-item-content-title">工作职责：</div>
                    <div v-for="(tex1, index) in item.text1" :key="index">{{ tex1 }}</div>
                    <div class="jobInfo-item-content-title">任职资格：</div>
                    <div v-for="(tex2) in item.text2" :key="tex2">{{ tex2 }}</div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
          </div>

        </div>
        <div class="content-right">
          <div class="content-right-title">应聘速递</div>
          <div class="content-right-describe">邮件中请发送相关作品和简历</div>
          <div class="content-right-item">
            <div class="line"></div>
            <div class="text">QQ：2505065613</div>
          </div>
          <div class="content-right-item">
            <div class="line"></div>
            <div class="text">E-mail：hr@zdjoys.com</div>
          </div>
          <div class="content-right-item">
            <div class="line"></div>
            <div class="text">联系人：李女士</div>
          </div>
          <div class="content-right-item">
            <div class="line"></div>
            <div class="text">联系电话：0571-56683852</div>
          </div>

        </div>
      </div>
    </div>

    <!-- <Bottom></Bottom> -->

  </div>
</template>

<script>
// import Bottom from '../components/bottom.vue'
export default {

  data () {
    return {
      // windowWidth: document.documentElement.clientWidth,
      // banner: {
      //   pcBanner: require('@/assets/updateBanner/pcJoin.png'),
      //   mbBanner: require('@/assets/updateBanner/mbGame.png')
      // },
      windowWidth: document.documentElement.clientWidth,
      banner: {
        pcBanner: require('@/assets/lunbo/jiaru.png'),
        mbBanner: require('@/assets/lunbo/m_jiaru.png')
      },
      activeName: 'first',
      detailShowOne: false,
      detailShowTwo: false,
      detailShowThree: false,
      codeList: [
        {
          title: '执行策划',
          location: '杭州',
          salary: '5K-7K',
          text1: ['1、负责游戏开发文档编写和执行；', ' 2、使用编辑器进行地图编辑或者进行任务关卡脚本的编写和配置；', '3、维护开发文档及开发表，进行简单的测试及报告并进行归档维护，保存最新最正确的版本文案；', ' 4、与制作人、程序员、美术和其他策划合作沟通落实各种工作（包括但不限于：界面布置、剧情设计、文案编写、填配置、副本关卡设计、音乐音效等）。'],
          text2: ['1、 要有基础的游戏项目常识,有一定的游戏项目经验, 有游戏策划从业经验者优先；数学相关专业优先考虑； ', '2、 具有丰富的游戏经验，对于游戏有深刻的认识，精通一款以上游戏并熟悉其数值体系特征；', ' 3、 熟练使用office办公软件 ', '4、 具备优秀的逻辑思维能力，工作积极主动，有奉献精神，心理承受能力强，能适应较大的工作压力。', '5、有游戏从业经验者优先。'],
          codeShow: false
        },
        {
          title: '游戏主策划',
          location: '杭州',
          salary: '15K-25K',
          text1: ['1、独立负责游戏产品的策划设计与管理工作；', '2、参与公司未来产品的规划建议；', '   3、制定书写游戏策划书，工作进度监督和结果检查等；', '4、根据项目决策，合理计划、分配项目内部策划资源；', '5、把握项目设计和发展方向，发现并及时解决问题；', '6、积极协调和其他各部门之间的关系。'],
          text2: ['1、具有丰富的游戏策划经验，具有大型游戏项目的设计经验；', '2、了解、掌握整个游戏产品开发的工作流程；', ' 3、掌握较强的游戏设计知识、技巧；', '4、掌握玩家心理，具备较强的市场分析能力；', '5、了解项目团队成员特点，具备较强的亲和力、领导能力、沟通能力及决策能力。'],
          codeShow: false
        },
        {
          title: '3D角色建模',
          location: '杭州',
          salary: '6K-10K',
          text1: ['1、根据原画设定制作3D人物模型；', '2、独立负责一个角色的完整的模型制作、UV分解、贴图绘制；', '3、与原画、动作及特效人员沟通并准确实现项目模型需求。'],
          text2: ['（此岗位附带会近期角色建模作品）', '1、扎实的美术基本功，美术或设计相关专业', '2、2年以上游戏设计工作经验；', ' 3、熟练使用3D设计软件；', ' 4、能熟练UV拆分、绘制贴图等工作；', '5、精通人体造型结构、对各种类型的动物形体有正确认识；', '6、精通低精度模型创建，对高精度模型有一定认识；', ' 7、具有良好沟通能力和团队合作精神；', '8、有较强的适应力、领悟力和理解能力。'],
          codeShow: false
        },
        {
          id: 0,
          title: 'H5游戏开发工程师',
          location: '杭州',
          salary: '8K-12K',
          text1: ['1、使用Egret或Laya进行H5游戏开发；', '2、能独立完成游戏逻辑的设计和开发；', ' 3、接入常见的第三方平台；', '4、与团队成员能良好地沟通，协同解决各类开发中的问题。'],
          text2: ['1、计算机相关专业毕业；', '2、1年以上页面游戏开发经验；', ' 3、精通Javascript, 有Egret QinCi和layabox开发经验者优先；', '4、有网站前/ 后端经验者优先；', '5、有较强的学习能力和一定的承压能力。'],
          codeShow: false
        },
        // {
        //   id: 1,

        //   title: 'TypeScript开发',
        //   location: '杭州',
        //   salary: '8K-12K',
        //   text1: ['1.使用TypeScript开发前后端业务逻辑；', '2.负责项目逻辑设计与编写；', '3.按照要求编写文档、编码、测试并能按时交付。'],
        //   text2: ['1.计算机软件开发相关专业, 有两年以上后端开发经验；', '2.精通TypeScript等技术与实现；', ' 3. 熟悉Web ，熟悉至少一个前端开发框架；', ' 4. 熟悉软件开发流程、软件架构、设计模式和设计方法；', ' 5.具备 Node.js 或 Vue3、CocosCreator 等开发经验者优先。'],
        //   codeShow: false
        // },
        {
          id: 2,

          title: '前端开发工程师',
          location: '杭州',
          salary: '8K-12K',
          text1: ['1、负责各游戏官网的开发与维护；', '2、 与后台开发人员配合进行网站集成优化，利用HTML，CSS，JavaScript等前端技术将静态网页设计集成在动态网站之上；', '3、 负责公司项目前端页面特殊效果的实现，改善用户体验。', ' 4、对数据库有一定了解，能使用ajax向后端请求数据。'],
          text2: ['1、一年以上web前端开发经验；', '2、熟悉并能手写HTML代码、DIV标签、CSS层叠样式表，多浏览器适配兼容， 熟悉移动端适配；', '3、熟悉应用HTML/ XHTML、CSS、JavaScript等Web前端开发技术，配合开发人员对产品界面进行优化；', '4、 熟悉vue / react 框架， 能熟练使用常见类库, ES6, webpack, SVN等;', ' 5、 能够独立负责项目的前端开发, 完成系统设计, 模块设计和开发工作；', ' 6、有PHP后端, MYSQL数据库经验，熟悉Thinkphp框架，能够独立开发优先。'],
          codeShow: false
        },
        // {
        //   id: 3,

        //   title: 'PHP开发工程师',
        //   location: '杭州',
        //   salary: '8K-12K',
        //   text1: ['1.负责网站PHP网站项目的程序编写及修改工作，实现客户功能需求；', '2.负责客户已有网站的PHP程序修改工作，满足其升级和改版的要求；', '3.负责网站的系统搭建工作；', ' 4.处理网站PHP网站项目运行中常见的技术问题，确保网站的正常运行；', '5.完成上级交待的其它工作。'],
        //   text2: ['1.1年以上PHP开发经验，热爱游戏编程；', ' 2.有web前端开发经验；', ' 3.精通PHP + MYSQL编程，熟悉PHP OOP编程；', '4.熟悉MySQL查询及索引优化、查询优化、熟悉常用数据结构与算法', '5.具备良好的表达和沟通能力；', ' 6.对工作有热情，能够在一定压力下工作。'],
        //   codeShow: false
        // },
        {
          id: 4,

          title: 'Unity3D开发工程师',
          location: '杭州',
          salary: '8K-12K',
          text1: ['1、负责游戏客户端框架的搭建；', '2、解决Unity3D客户端整体性能问题。'],
          text2: ['1、3年以上，多款大型网游开发经验并上线，本科以上学历，计算机相关专业；', '2、有C/ C++语言编程经验；', '3、熟练掌握Unity3D引擎，精通C#语言；', ' 4、具有良好的沟通能力和团队协作能力；工作认真负责，有良好的抗压能力；', ' 5、接入常见的第三方平台；', ' 6、对工作有热情，能够在一定压力下工作。'],
          codeShow: false
        }],
      operationList: [{
        title: '新媒体运营',
        location: '杭州',
        salary: '6K-8K',
        text1: ['1、负责公司各类互联网产品的运营和推广；', '2、发掘移动互联网营销新渠道、社交平台营销新模式；', ' 3、熟悉ASO、SEO者优先。'],
        text2: ['1、一年以上运营相关工作经验；', '2、喜欢玩各类社交平台；', '    3、有较强创新和思考能力。'],
        codeShow: false
      },
      {
        title: '游戏运营',
        location: '杭州',
        salary: '6K-10K',
        text1: ['1、对游戏平台的运营非常熟悉，产品的接入和上线运营工作；', '2、配合进行产品线上与线下活动方案的策划；', ' 3、协助完成游戏评测并能进行游戏上线运营测试等；', ' 4、进行游戏运营数据分析，分析得出可行性优化方案；'],
        text2: ['1、熟悉游戏行业运营模式，有ASO、刷榜等工作经验者优先；', '2、善于与人沟通，思维敏捷、富有创造力，工作态度细致认真；', '3、热爱手机游戏，有优秀的创意、文案能力。'],
        codeShow: false
      },
      {
        title: '海外运营',
        location: '杭州',
        salary: '6K-10K',
        text1: ['1、负责手游产品在海外市场的发行、运营工作；', '2、制定游戏海外运营规划、并组织和监督运营实施；', ' 3、负责搭建海外的论坛，Facebook，googleplay, Twitter等；', '4、整理海外市场区域游戏分析，协助研发团队进行产品调整；'],
        text2: ['1、1年以上手游游戏海外市场发行运营经验；', '2、能熟练运用英文进行书面和口语交流；', '3、拥有较高的数据敏感度，擅长数据分析；', ' 4、有较强的抗压能力，优秀的团队合作能力。'],
        codeShow: false
      }
        // {
        //   title: '抖音运营',
        //   location: '杭州',
        //   salary: '5K-8K',

        //   text1: ['1.负责公司抖音的日常运营，提高产品曝光度和知名度；', '2.独立完成公司产品短视频策划、后期编辑优化等整个创意视频的制作；', ' 3.研究抖音等短视频平台的热门视频内容、玩法、新功能，挖掘潜力玩法，并进行产品推广；', '   4.建立有效运营手段增加粉丝数量和提升用户活跃度；', '5.持续对运营数据进行分析，不断优化运营策略。'],
        //   text2: ['1.热爱游戏行业', '2.一年以上抖音运营相关工作经验；', '3.熟悉各类社交平台；', ' 4.有较强创新和思考能力。'],
        //   codeShow: false
        // },
        // {
        //   title: '电商运营',
        //   location: '杭州',
        //   salary: '6K-12K',
        //   text1: ['1.负责各电商平台自然量、买量导流工作；', '2.跟进活动信息，策划与跟进推广投放方案，完成数据的统计、分析以及其他临时性工作。'],
        //   text2: ['1.1年以上电商运营工作经验；', '2.熟悉各电商、直播平台规则。'],
        //   codeShow: false
        // }
      ],
      planList: [{
        title: '执行策划',
        location: '杭州',
        salary: '5K-7K',
        text1: ['1、负责游戏开发文档编写和执行；', ' 2、使用编辑器进行地图编辑或者进行任务关卡脚本的编写和配置；', '3、维护开发文档及开发表，进行简单的测试及报告并进行归档维护，保存最新最正确的版本文案；', ' 4、与制作人、程序员、美术和其他策划合作沟通落实各种工作（包括但不限于：界面布置、剧情设计、文案编写、填配置、副本关卡设计、音乐音效等）。'],
        text2: ['1、 要有基础的游戏项目常识,有一定的游戏项目经验, 有游戏策划从业经验者优先；数学相关专业优先考虑； ', '2、 具有丰富的游戏经验，对于游戏有深刻的认识，精通一款以上游戏并熟悉其数值体系特征；', '    3.有较强创新和思考能力。', ' 3、 熟练使用office办公软件 ', '4、 具备优秀的逻辑思维能力，工作积极主动，有奉献精神，心理承受能力强，能适应较大的工作压力。', '5、有游戏从业经验者优先。'],
        codeShow: false
      },
      // {
      //   title: '游戏策划',
      //   location: '杭州',
      //   salary: '8K-13K',
      //   text1: ['1.负责手游的策划，整体把控游戏的设计方向、画面风格、策划各方面内容的质量；', '2.设定玩法规则，设计游戏的主要模块和关卡、设计操作流程和界面布局，撰写策划文档；', '3.根据游戏设计意图和规划，进行各种游戏玩法设定；', '4.与研发人员和美术紧密配合，及时沟通具体实现过程中遇到的各种问题，确保游戏按计划完成。'],
      //   text2: ['1、大专及以上学历优秀应届生；', '2、学习能力强，有耐心，有责任心；', '  3、热爱游戏，游戏经验丰富，熟悉玩家心理；', '4、沟通能力好，富有团队协作精神，工作热情积极；', '5、具备较好的逻辑思维能力和执行能力。'],
      //   codeShow: false
      // },
      // {
      //   title: '游戏策划实习生',
      //   location: '杭州',
      //   salary: '2500',
      //   text1: ['1、协助游戏策划数据编辑与维护；', '2、协助游戏活动规划设计与相关策划案撰写；', '3、协助游戏系统改进与新系统设计；', '4、进行游戏资源、文档、数据整理；', '5、协助完成游戏功能测试及调试。'],
      //   text2: ['1、大专以上学历，1年以上手游游戏海外市场发行运营经验；熟悉该区域游戏发行运营模式；有良好的海外各区域渠道资源优先；', '2、能熟练运用英文进行书面和口语交流；', '3、拥有较高的数据敏感度，擅长数据分析（运营回收数据与市场数据）', ' 4、有较强的抗压能力，优秀的团队合作能力。'],
      //   codeShow: false
      // },
      {
        title: '游戏主策划',
        location: '杭州',
        salary: '15K-25K',
        text1: ['1.独立负责游戏产品的策划设计与管理工作；', '2.参与公司未来产品的规划建议；', '   3.制定书写游戏策划书，工作进度监督和结果检查等；', '4.根据项目决策，合理计划、分配项目内部策划资源；', '5.把握项目设计和发展方向，发现并及时解决问题；', '6.积极协调和其他各部门之间的关系。'],
        text2: ['1.具有丰富的游戏策划经验，具有大型游戏项目的设计经验；', '2.了解、掌握整个游戏产品开发的工作流程；', ' 3.掌握较强的游戏设计知识、技巧；', '4.掌握玩家心理，具备较强的市场分析能力；', '5.了解项目团队成员特点，具备较强的亲和力、领导能力、沟通能力及决策能力。'],
        codeShow: false
      }
        // {
        //   title: '文案策划',
        //   location: '杭州',
        //   salary: '5K-8K',
        //   text1: ['1.主要负责游戏内的文案撰写。', '2.了解产品需求，主动及时反馈工作进展；', '3.协助参与制作各类项目方案，协助策划、创意、传播话题制订等工作。'],
        //   text2: ['1.本科及以上，中文、新闻、广告等相关专业优先；', '2.热爱文字工作，文案基础好，喜爱文字冒险类游戏者优先；', '3点子多，脑洞大，勤思考，充满好奇心，有逻辑；', ' 4.沟通能力强、思维敏捷、有创新能力，团队意识强；'],
        //   codeShow: false
        // }
      ],
      artList: [
        // {
        //   title: '简笔漫画师',
        //   location: '杭州',
        //   salary: '5K-7K',
        //   text1: ['简历投递时请附上个人相关作品', '1、负责公司原创作品，动漫IP漫画项目制作，需要拥有独立绘制漫画分镜的能力；', '2、独立完成漫画勾线及上色方面的工作；', '3、适应多种美术设计风格，作品富有想法、有创意。'],
        //   text2: ['1、有良好的美术基础和电脑绘画能力，熟练使用Photoshop、sai、comicstudio等绘图软件 ；', '2、有良好的色彩感觉和独特绘画风格，对流行元素趋势有一定的洞察能力；', '3、擅长人物、场景绘制、上色等工作，了解漫画表达语言及创作流程 ；', '4、有良好的沟通能力与团队合作精神， 责任心强，有良好的职业素养。'],
        //   codeShow: false
        // },
        {
          title: '3D角色建模',
          location: '杭州',
          salary: '6K-10K',
          text1: ['1.根据原画设定制作3D人物模型；', '2.独立负责一个角色的完整的模型制作、UV分解、贴图绘制；', '3.与原画、动作及特效人员沟通并准确实现项目模型需求。'],
          text2: ['（此岗位附带会近期角色建模作品）', '1.扎实的美术基本功，美术或设计相关专业', '2.2年以上游戏设计工作经验；', ' 3.熟练使用3D设计软件；', ' 4.能熟练UV拆分、绘制贴图等工作；', '5.精通人体造型结构、对各种类型的动物形体有正确认识；', '6.精通低精度模型创建，对高精度模型有一定认识；', ' 7.具有良好沟通能力和团队合作精神；', '8.有较强的适应力、领悟力和理解能力。'],
          codeShow: false
        }
      ],
      saleList: [{
        title: '实习销售',
        location: '杭州',
        salary: '2500',
        text1: ['1、 合理利用公司提供的平台和资源寻找目标客户、开拓市场、与客户建立联系，明确客户需求；', '2、 根据部门要求达成工作任务与目标。'],
        text2: ['1、良好的人际沟通、表达能力、学习能力；', '2、对市场开拓有临场应变能力。'],
        codeShow: false
      },
      {
        title: '元宇宙商务销售',
        location: '杭州',
        salary: '7K-12K',
        text1: ['1.负责客户信息收集、跟进联系、了解客户需求，主要内容为：为客户提供基于元宇宙技术的新营销解决全案；', ' 2.对公司内部各部门统筹协调，以满足客户的需求。'],
        text2: ['1.有1-2年市场销售经验，性格外向，有良好的沟通能力和责任心；', '2.有快消品或旅游行业从业经验；', '3.对新领域知识有自我学习能力；', '4.有较强的跨部门协调能力；', '5.懂得挖掘客户需求，并理解消化。'],
        codeShow: false
      },
      {
        title: '达人带货主播',
        location: '杭州',
        salary: '10K-15K',
        text1: ['1．通过抖音直播平台，向粉丝讲解产品卖点，引导下单；', '2．与粉丝互动，活跃气氛，引导粉丝关注直播间，提升在线人数；', '3．不断反馈调整，优化直播内容，提高用户参与度，增强粉丝粘性；', '4．参与拍摄短视频及内容创作，配合团队完成日常工作需求。'],
        text2: ['1．有无经验均可（公司有完整的一套培训过程），有过抖音直播经验的优先考虑；', '2. 对抖音直播有一定的了解。热情大胆，放得开。（直播话术和产品会进行培训）', '  3. 对珠宝有一定了解，对时尚和美感比较敏锐，沟通能力强，具备一定的抗压能力。', '4. 口齿伶俐，喜欢穿搭，善于表达，应变能力强，能主动熟悉产品细节，及时与粉丝互动；', ' 5. 有0 - 1起号经验会憋单的优先'],
        codeShow: false
      }
      ],
      businessList: [
        {
          title: '商务专员',
          location: '杭州',
          salary: '3.5k+提成',
          text1: ['1、 合理利用公司提供的平台和资源寻找目标客户、开拓市场、与客户建立联系，明确客户需求；', '2、 联络、跟进客户，包括游戏版号、文网文、ICP、专利、商标、科技项目申报等方面为客户提供专业的咨询与服务；', ' 3、 收集、整理客户信息，维护客户关系，提高客户满意度；', '  4、 根据部门要求达成工作任务与目标。'],
          text2: ['1、对知识产权和游戏行业工作有兴趣；', '2、良好的人际沟通、表达能力、学习能力、市场开拓能力、临场应变能力；', '3、知识产权专业优先。'],
          codeShow: false
        },
        // {
        //   title: '资深商务',
        //   location: '杭州',
        //   salary: '10k-15k',
        //   text1: ['1、3年以上游戏行业工作经验，有行业资源，能完成公司交待的商务任务（如产品代理、团队投资、媒体合作等）；', '2、 根据部门要求达成工作任务与目标。'],
        //   text2: ['1、热爱游戏行业；', '2、优秀的人格品质，良好的职业素养，愿与公司共发展。'],
        //   codeShow: false
        // },
        {
          title: '渠道商务',
          location: '杭州',
          salary: '8K-10K',
          text1: ['1、负责游戏市场的商务合作拓展，寻找跟进合作商资源及客户需求信息；', '2、运营商、渠道、媒体等游戏相关资源开拓。'],
          text2: ['1、熟悉互联网及游戏行业内合作运营的具体情况，拥有一定区域或平台人脉资源；', '2、有手游发行特别是单机游戏发行经验，和各个手游渠道有良好的关系;', ' 3、敏锐的市场洞察力，对网络游戏合作模式和盈利模式有较深了解；', '4、优秀的人际交往、商务拓展和任务执行能力，并能承受工作压力；', ' 5、工作认真细致，具备高度的责任心、团队协作意识和吃苦耐劳精神。'],
          codeShow: false
        }

        // {
        //   title: '直播电商商务',
        //   location: '杭州',
        //   salary: '8K-15K',
        //   text1: ['1.自有源头工厂（服装、快消品），负责开发与对接合作伙伴，为客户提供供应链。'],
        //   text2: ['1.有抖音、快手等MCN机构或带货达人资源；', '2.具有较强的谈判能力、沟通能力，抗压能力强。'],
        //   codeShow: false
        // },
        // {
        //   title: '知识产权销售商务',
        //   location: '杭州',
        //   salary: '5K-10K',
        //   text1: ['1．寻找目标客户，开拓市场，与客户建立联系；', '2．联络、跟进客户，包括游戏版号、文网文、ICP、专利、商标、科技项目申报等方面为客户提供专业的咨询与服务；'],
        //   text2: ['1．对知识产权和游戏行业工作有兴趣；', '2．良好的人际沟通、市场开拓能力。'],
        //   codeShow: false
        // }

      ],
      otherList: [
        {
          title: '商务专员',
          location: '杭州',
          salary: '3.5k+提成',
          text1: ['1、 合理利用公司提供的平台和资源寻找目标客户、开拓市场、与客户建立联系，明确客户需求；', '2、 联络、跟进客户，包括游戏版号、文网文、ICP、专利、商标、科技项目申报等方面为客户提供专业的咨询与服务；', ' 3、 收集、整理客户信息，维护客户关系，提高客户满意度；', '  4、 根据部门要求达成工作任务与目标。'],
          text2: ['1、对知识产权和游戏行业工作有兴趣；', '2、良好的人际沟通、表达能力、学习能力、市场开拓能力、临场应变能力；', '3、知识产权专业优先。'],
          codeShow: false
        },
        {
          title: '渠道商务',
          location: '杭州',
          salary: '8K-10K',
          text1: ['1、负责游戏市场的商务合作拓展，寻找跟进合作商资源及客户需求信息；', '2、运营商、渠道、媒体等游戏相关资源开拓。'],
          text2: ['1、熟悉互联网及游戏行业内合作运营的具体情况，拥有一定区域或平台人脉资源；', '2、有手游发行特别是单机游戏发行经验，和各个手游渠道有良好的关系;', ' 3、敏锐的市场洞察力，对网络游戏合作模式和盈利模式有较深了解；', '4、优秀的人际交往、商务拓展和任务执行能力，并能承受工作压力；', ' 5、工作认真细致，具备高度的责任心、团队协作意识和吃苦耐劳精神。'],
          codeShow: false
        },
        // {
        //   title: 'ASO优化',
        //   location: '杭州',
        //   salary: '8-10k',
        //   text1: ['1、负责ASO优化工作，熟悉IOS排名算法变化，制定相应ASO策略；', '2、能独立完成游戏在应用商店的各榜排名、应用类别排名、搜索热词排名；', '3、制定和实施ASO优化策略，提升在各应用商店及各搜索引擎的排名，负责分析、评估和监控游戏关键词，并按照关键词数据进行相应的优化调整，为优化效果及目标负责；', '4、根据游戏的下载量、销量等数据，制定优化策略，按阶段汇报优化监控结果和研究结果，并跟踪推广；'],
        //   text2: ['1、热爱游戏，至少1年以上ASO优化工作经验；', '2、具有优秀的沟通能力，逻辑清晰，善于组织策划，有严谨而有效的投放思路；', '3、责任心强，能承受较大的工作压力，具有较强的团队合作精神；', '4、熟悉IOS投放和ASO优化，有完整参与过至少1款游戏的推广经验。'],
        //   codeShow: false
        // },
        {
          title: '游戏出版编辑',
          location: '杭州',
          salary: '10k-15k',
          text1: ['1、负责游戏出版相关资料的审理和编写；', '2、关注行业发展，熟悉掌握游戏出版服务政策与管理规范的最新要求。'],
          text2: ['1、热爱游戏行业；', '2、耐心细致，工作积极主动，执行力强；', '3、具备良好的语言文字功底；', ' 4、有出版专业职业资格证书优先。'],
          codeShow: false
        },
        // {
        //   title: '游戏特效',
        //   location: '杭州',
        //   salary: '8K-12K',
        //   text1: ['（请附带作品）', '1.制作游戏中所需场景动画光效等特殊效果；', ' 2.有良好的审美能力，一定的绘画上色能力；', ' 3.熟练使用AE，Fusion，3Ds MAX，PhotoShop以及U3D等软件及特效插件；', '     4.擅长游戏特效制作，能独立进行特效制作，熟悉各种主流游戏产品的特效制作方法；', ' 5.有游戏项目制作经验者优先考虑。'],
        //   text2: ['1.一年以上相关2D或3D美术特效经验；', '2.具有良好的团队协作意识，能很好的与他人协同完成工作并且能独立解决工作上的难题；', '3.热爱游戏，喜欢玩各类游戏。'],
        //   codeShow: false
        // },
        {
          title: '产品经理',
          location: '杭州',
          salary: '8K-12K',
          text1: ['1、负责公司游戏产品的生命周期管理，包括从封测阶段到公测全部期间的游戏付费及留存的调优；', '2、负责与研发对接，跟进游戏不同阶段的版本内容及研发策划案的分析，并对产品版本进行测试、上线等工作；', '3、负责推进游戏与运营部相关对接的项目管控，能及时根据用户反馈及细分产品各维度的数据分析，持续改善产品体验，持续提高线上不同游戏版本的效果。'],
          text2: ['1、1-3年的游戏产品经验，负责过成功的游戏项目，有丰富的产品调优、项目管理相关经验；', '2、有较强的用户需求把握能力和数据分析能力；', '3、较强的逻辑思维能力，能够深度体验产品，很好的规划和设计产品功能、使用流程与交互体验；', '4、有很强的沟通、协调，推进能力，有很好的抗压能力，能够有效地组织和推进各项目。'],
          codeShow: false
        }
        // {
        //   title: '游戏公会专员',
        //   location: '杭州',
        //   salary: '5K(有奖金和绩效)',
        //   text1: ['1.熟知本公司产品，并向玩家或公会介绍公司产品及可给福利；', '2.寻找和挖掘有意向的玩家、公会进驻游戏；', '3.处理解决玩家相关问题。'],
        //   text2: ['1.有游戏行业工作经验；', '2.有玩家、公会资源；', '3.较强的语言组织能力，良好的交流沟通能力。'],
        //   codeShow: false
        // }

      ]
    }
  },
  methods: {
    codeViewDetailBtn (currentItem) {
      currentItem.codeShow = !currentItem.codeShow
      // this.codeList.forEach(element => {
      //   element.codeShow = !element.codeShow
      // })
    },
    operationViewDetailBtn (currentItem) {
      currentItem.codeShow = !currentItem.codeShow
    },
    planViewDetailBtn (currentItem) {
      currentItem.codeShow = !currentItem.codeShow
    },
    artViewDetailBtn (currentItem) {
      currentItem.codeShow = !currentItem.codeShow
    },
    saleViewDetailBtn (currentItem) {
      currentItem.codeShow = !currentItem.codeShow
    },
    businessViewDetailBtn (currentItem) {
      currentItem.codeShow = !currentItem.codeShow
    },
    otherViewDetailBtn (currentItem) {
      currentItem.codeShow = !currentItem.codeShow
    },
    handleClick () {

    },
    viewDetailBtnOne () {
      this.detailShowOne = !this.detailShowOne
      this.detailShowTwo = false
      this.detailShowThree = false
    },
    viewDetailBtnTwo () {
      this.detailShowTwo = !this.detailShowTwo
      this.detailShowThree = false
      this.detailShowOne = false
    },
    viewDetailBtnThree () {
      this.detailShowThree = !this.detailShowThree
      this.detailShowOne = false
      this.detailShowTwo = false
    }
  },
  created () {
    window.scrollTo(0, 0)
  },
  components: {
    // Bottom
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 750px) {
  .joinOur {
    padding-top: 50px;

    .head-box {
      .head {
        height: 150px;
        width: 100%;
        img {
          width: 100%;
          height: 100%;
        }
        // background: url('../assets/newImg/mb_jrwm.png') no-repeat 100% 100%;
        // font-size: 25px;
        // text-align: center;
        // line-height: 250px;
        // color: #ffffff;
        // background-size: cover;
      }
    }

    .content-box {
      margin-top: 25px;
      .content {
        // width: 1690px;
        margin: 0 auto;
        // background-color: skyblue;
        margin-top: 10px;
        // display: flex;
        // justify-content: space-between;

        .content-left {
          // padding: 0 15px;
          width: 100%;
          // height: 500px;
          // padding:10px 10px;
          // background-color: blueviolet;

          .content-left-title {
            font-size: 16px;
            margin-bottom: 5px;
            font-weight: 600;
            padding: 0 15px;
          }

          .jobInfo-box{
            .jobInfo {
            width: 345px;
            margin: 0 auto;
            ::v-deep() {
              .el-tabs__item.is-active {
                color: #ff8714 !important;
              }

              .el-tabs__item:hover {
                color: #ff8714;
              }

              .el-tabs__active-bar {
                background-color: #ff8714;
              }

              .el-tabs__nav-wrap::after {
                background-color: transparent;
              }
            }

            .jobInfo-item {
              display: flex;
              // padding: 0 20px;
              height: 50px;
              // background-color: blueviolet;
              justify-content: space-between;
              align-items: center;
              border-bottom: 2px solid #f2f2f4;
              // .tab-first{
              //   margin-left: 20px;
              // }

              .jobInfo-item-left {
                & div:nth-child(1) {
                  position: relative;
                  font-size: 16px;
                  height: 33px;
                  width: 241px;
                  color: #ff8714;
                  margin-bottom: 5px;

                  &::before {
                    content: ' ';
                    position: absolute;
                    width: 3px;
                    height: 16px;
                    display: block;
                    left: -8px;
                    top: 50%;
                    background-color: #ff8714;
                    transform: translateY(-50%);
                  }
                }

                & div:nth-child(2) {
                  font-size: 14px;
                }
              }

              .jobInfo-item-right {
                width: 70px;
                height: 25px;
                border: 1px solid #ff8714;
                text-align: center;
                line-height: 25px;
                color: #ff8714;
                font-size: 12px;
                cursor: pointer;

                .el-icon-arrow-down {
                  margin-left: 3px;
                }
              }
            }

            .jobInfo-item-content {
              font-size: 12px;

              div {
                margin: 5px 0;
              }

              .jobInfo-item-content-title {
                margin: 10px 0;
              }
            }
          }
          }

        }

        .content-right {
          // display: none;
          margin: 20px;
          width: 200px;
          // height: 320px;
          background-color: #fff;
          // padding: 20px 20px;
          padding: 0 20px;
          font-weight: 500;

          .content-right-title {
            height: 30px;
            font-size: 16px;
            // background-color: aqua;
            line-height: 30px;
            border-bottom: 1px solid #c9cacf;
            margin-bottom: 5px;
          }

          .content-right-describe {
            font-size: 12px;
          }

          .content-right-item {
            display: flex;
            align-items: center;
            font-size: 12px;
            // background-color: blueviolet;
            height: 30px;
            // width: 150px;
            // font-weight: 600;
            position: relative;

            .line {
              width: 3px;
              height: 13px;
              background-color: #ff8714;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

// pc端
@media (min-width: 992px) {
  .joinOur {
    padding-top: 50px;
    background-color: #f2f2f4;

    .head-box {
      .head {
        height: 400px;
        width: 1400px;
        margin: 0 auto;
        // background: url('../assets/newImg/pc_jrwm.png') no-repeat no-repeat;
        // background-size: cover;
        // font-size: 50px;
        // text-align: center;
        // line-height: 400px;
        // color: #ffffff;
        // background-size: cover;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .content-box {
      .content {
        width: 1200px;
        margin: 0 auto;
        // margin-top: 70px;
        padding: 80px 0;
        // padding-bottom: 000px;

        // background-color: skyblue;
        display: flex;
        justify-content: center;

        .content-left {
          width: 1000px;
          padding: 35px 41px;
          background-color: #fff;
          margin-right: 50px;

          .content-left-title {
            font-size: 26px;
            margin-bottom: 20px;
            font-weight: 600;
            color: #262626;
          }

          .jobInfo {
            ::v-deep() {
              .el-tabs__item.is-active {
                color: #ff8714 !important;
              }

              .el-tabs__item:hover {
                color: #ff8714;
              }

              .el-tabs__active-bar {
                background-color: #ff8714;
              }

              .el-tabs__nav-wrap::after {
                background-color: transparent;
              }
            }

            .jobInfo-item {
              display: flex;
              padding: 0 20px;
              height: 80px;
              // background-color: blueviolet;
              justify-content: space-between;
              align-items: center;
              border-bottom: 2px solid #f2f2f4;

              .jobInfo-item-left {
                & div:nth-child(1) {
                  position: relative;
                  font-size: 22px;
                  height: 33px;
                  width: 241px;
                  color: #ff8714;
                  margin-bottom: 10px;

                  &::before {
                    content: ' ';
                    position: absolute;
                    width: 3px;
                    height: 16px;
                    display: block;
                    left: -8px;
                    top: 50%;
                    background-color: #ff8714;
                    transform: translateY(-50%);
                  }
                }

                & div:nth-child(2) {
                  font-size: 14px;
                }
              }

              .jobInfo-item-right {
                width: 100px;
                height: 30px;
                border: 1px solid #ff8714;
                text-align: center;
                line-height: 30px;
                color: #ff8714;
                font-size: 16px;
                cursor: pointer;

                .el-icon-arrow-down {
                  margin-left: 3px;
                }
              }
            }

            .jobInfo-item-content {
              font-size: 14px;

              div {
                margin: 5px 0;
              }

              .jobInfo-item-content-title {
                margin: 10px 0;
              }
            }
          }
        }

        .content-right {
          width: 400px;
          height: 320px;
          background-color: #fff;
          padding: 40px 60px;
          font-weight: 500;

          .content-right-title {
            height: 60px;
            font-size: 20px;
            // background-color: aqua;
            line-height: 60px;
            border-bottom: 1px solid #c9cacf;
            margin-bottom: 15px;
          }

          .content-right-describe {
            font-size: 16px;
          }

          .content-right-item {
            display: flex;
            align-items: center;
            font-size: 15px;
            // background-color: blueviolet;
            height: 30px;
            width: 200px;
            // font-weight: 600;
            position: relative;

            .line {
              width: 3px;
              height: 13px;
              background-color: #ff8714;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
